import "./StoneContent.scss"
import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import CS from '@root/i18n/cs';
import { NavLink } from "react-router-dom";

import Carousel, { Modal, ModalGateway } from "react-images";
import { useNavigate } from 'react-router-dom';
import { AiOutlineLike, AiFillLock } from 'react-icons/ai';
import { FaRegCommentAlt } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { MdOutlineUnfoldMore, MdGpsFixed } from 'react-icons/md';

import { useMessageManager } from '@root/contexts/MessageManager';
import { showMessage, showConfirmDialog } from '@root/actions/messageManager-actions';
import { ConfirmTemplates } from '@root/templates/confirmTemplates';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { useAuth } from '@root/contexts/AuthContext';
import { StoneState, Roles } from '@root/helpers/constants';

import { 
    Stone, 
    ZipItem, 
    StoneItem,
    updateLikes,
    deleteStone
} from "@root/service/stoneService";
import { mapCommentCount } from '@root/helpers/shared';
import Comments from './Comments';
import StoneGallery from './StoneGallery';
import StonePreviewGallery from './StonePreviewGallery';
// import Gallery from "react-photo-gallery";

export interface StoneContentProps {
    index: number;
    item: Stone;
    stones: Stone[];
    setStones: Dispatch<SetStateAction<Stone[]>>;
    setDefaultZip?: Dispatch<SetStateAction<string>>;
    additionalContent?: any;
    disableActions?: boolean;
    customActions?: any;
    anotate?: boolean;
    useNativeGallery?: boolean;
    setBusy: any;
    stonePointersHook?: [any, Dispatch<SetStateAction<any>>];
}

export default function StoneContent({ ...rest }: StoneContentProps) {

    const {
        index,
        item,
        stones,
        setStones,
        additionalContent,
        disableActions,
        customActions,
        anotate,
        useNativeGallery,
        setBusy,
        stonePointersHook,
    } = rest;

    const { currentUser, refreshUser } = useAuth();
    const navigate = useNavigate();

    const [likes, setLikes] = useState<any>(item.likes);
    const [stonePointers] = useState(item.stones.map((stone: any) => stone.pointers?.length).reduce((a, b) => a + b, 0));
    const [currentImage, setCurrentImage] = useState(0);
    const [activeGroup, setActiveGroup] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [isRejected] = useState(item.changes && item.changes.find((item: any) => item.type === StoneState.REJECT));
    
    const [confirmDialogDeleteStoneId, setConfirmDialogDeleteStoneId] = useState<any>(0);
    const { stateMessages, dispatchMessages } = useMessageManager();

    function getPhotos(data: any): any {
        const item = data.map((item: StoneItem) => {
            return {
                stoneId: item.stoneId,
                src: item.url,
                width: item.info.width,
                height: item.info.height,
                caption: item.zipList.map((item: ZipItem) => `${item.zip} - ${item.district}`),
                pointers: item.pointers
            };
        });
        return item;
    }

    const [photos] = useState(getPhotos(item.stones));

    const openLightbox = useCallback((index: number, group: number) => {
        setActiveGroup(group);
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    // actions
    // delete stone
    useEffect(() => {
        (async () => {
            if (stateMessages.confirmDialog && stateMessages.confirmDialog.confirmed) {
                if (stateMessages.confirmDialog.id === confirmDialogDeleteStoneId) {
                    setBusy(true);
                    debugger;
                    try {
                        await deleteStone(confirmDialogDeleteStoneId);
                        await refreshUser();
                        history.back();
                    } catch(error: any) {
                        setBusy(false);
                        dispatchMessages(showMessage({
                            type: 'error',
                            message: error.message
                        }));
                    }
                }
            }
        })();
    }, [stateMessages])

    async function deleteStoneHandler(item: any) {
        let template = isAdmin && !isUserStoneOwner ? ConfirmTemplates.ADMIN_REMOVE_STONE : ConfirmTemplates.REMOVE_STONE;
        setConfirmDialogDeleteStoneId(item.trackingId);
        dispatchMessages(showConfirmDialog(item.trackingId, template));
    }

    // async function editStoneHandler(item: Stone) {
    //     navigate('/editstone/' + item.trackingId);
    // }

    async function handleIncreaseLikes(item: any) {
        let newLikes = [...likes];

        let exists = newLikes.findIndex(like => like.userId === currentUser.uid);
        if (exists === -1) {
            if (!!~newLikes.indexOf(currentUser.uid)) {
                newLikes.splice(newLikes.indexOf(currentUser.uid), 1);
            } else {
                newLikes.push(currentUser.uid);    
            }
        } else {  // exist as a object, remove
            newLikes.splice(exists, 1);
        }
        setLikes(newLikes);
        try {
            newLikes = await updateLikes(item.trackingId);
            setLikes(newLikes);
        } catch(e) {
            setBusy(false);
        }
    }

    const isAdmin = currentUser.info.user.role === Roles.ADMIN;
    const isUserStoneOwner = item.uid === currentUser.info.user.userId;

    return (
        <>
            <div key={index} className="col col-12 col-md-6">
                <div className="card trackItem">
                    {!item.stones && <h1>loading</h1>}
                    <div className="card-body trackItem-header">
                        {item.changes && item.changes.find((item: any) => item.type === StoneState.REJECT) &&
                            <div className="alert alert-danger" role="alert">
                                <h6>{CS.components.stoneContent.rejectionReason}</h6>

                                <ul className="list-group">
                                    {item.changes.filter((item: any) => item.type === StoneState.REJECT).map((item: any, index: number) => {
                                        return (
                                            <li className="list-group-item list-group-item-danger" key={index}>
                                                {item.date} {CS.components.stoneContent.rejectedBy} <b>{item.user?.userName}</b> - {item.message}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        }

                        <div className="user-item">

                            {item.user?.photoURL &&
                                <span className="avatar" onClick={() => {
                                    navigate(`/stones/public/${item.user.userId}`);
                                }}>
                                    <img src={item.user.photoURL} className="user-item-avatar" alt="Avatar"></img>
                                </span>
                            }

                            {(!item.user || !item.user.photoURL) &&
                                <span className="avatar">
                                    <img src="./images/user.png" alt="Avatar"></img>
                                </span>
                            }
                            <h5 className="card-title">{item.user?.userName}</h5>
                            {/* <div className="user-status">{item.user.info.stones} kamínků</div> */}

                            <div className="created-date">{item.date} {stonePointers > 0 ? `- ${CS.components.stoneContent.stoneCount} ${stonePointers}` : ''}</div>


                            {item.positionLat &&
                                <span className="location">
                                    <MdGpsFixed color="blue"  title={item.positionLabel ? item.positionLabel.label : ''}/>
                                    &nbsp;{item.positionData ? item.positionData.label : ''}
                                </span>
                            }
                            {item.state === StoneState.PRIVATE && 
                                <><AiFillLock color="red" />&nbsp;</>
                            }
                            <div className="trackItem-header-zipList">
                                {item.zipRawList.map((item: ZipItem, index: any) => {
                                    return (
                                        <span key={index} onClick={() => {
                                            // if (setDefaultZip) {
                                            //     setDefaultZip(item.zip)
                                            // }
                                        }} className="badge rounded-pill bg-primary">
                                            <span className="badge-label">{`${item.zip} - ${item.district}`}</span>
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        {!useNativeGallery &&
                            <StoneGallery
                                photos={photos}
                                anotate={anotate}
                                stonePointersHook={stonePointersHook}
                            ></StoneGallery>
                        }

                        {useNativeGallery &&
                            <StonePreviewGallery photos={photos.map((photo: any) => {
                                const { stoneId, ...resp } = photo;
                                return resp;
                            // @ts-ignore
                            })} onClick={(e: any, data: any) => {
                                openLightbox(data.index, index);
                            }}></StonePreviewGallery>
                        }

                        {/* {useNativeGallery &&
                            <Gallery photos={photos.map((photo: any) => {
                                const { stoneId, ...resp } = photo;
                                return resp;
                            // @ts-ignore
                            })} onClick={(e: any, data: any) => {
                                openLightbox(data.index, index);
                            }} />
                        } */}

                        {useNativeGallery && index === activeGroup && 
                            // @ts-ignore
                            <ModalGateway>
                                {viewerIsOpen ? (
                                    <Modal onClose={closeLightbox}>
                                        <Carousel
                                            currentIndex={currentImage}
                                            // hideControlsWhenIdle={false}
                                            views={photos.map((x: any) => ({
                                                ...x,
                                                srcset: x.srcSet,
                                                caption: x.caption
                                            }))}
                                        />
                                    </Modal>
                                ) : null}
                            </ModalGateway>
                        }

                        {item.description &&
                            <p className="card-text description">{item.description}</p>
                        }

                        {!isRejected &&
                            <div className="show-detail">
                                <NavLink className="nav-link" to={`/stone/${item.trackingId}`}>
                                    {CS.components.stoneContent.showDetail} <MdOutlineUnfoldMore />
                                </NavLink>
                            </div>
                        }
                        {additionalContent}

                        {!disableActions && !isRejected && item.state !== StoneState.PRIVATE &&
                            <>
                                <div className="stone-info">
                                    <div className="stone-info-like-count">
                                        {likes.length > 0 &&
                                            <OverlayTrigger
                                                overlay={<Tooltip className="likes-tooltip">
                                                    {likes.filter((like: any) => like.userName).map((like: any, index: number) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {like.userName}<br/>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Tooltip>}
                                                >
                                                    <Button variant="link">
                                                        <AiOutlineLike  /> {likes.length}
                                                    </Button>
                                            </OverlayTrigger>
                                        }
                                    </div>

                                    <div className="stone-info-comments-count" onClick={() => {
                                        item.showComments = !item.showComments
                                        setStones([...stones]);
                                    }}>
                                        {item.comments.length > 0 &&
                                            <>
                                                {item.comments.length} {mapCommentCount(item.comments.length)}
                                            </>
                                        } 
                                    </div>
                                </div>
                                <hr />
                            </>
                        }

                        {/* {!disableActions && item.state !== StoneState.PRIVATE && */}
                        {!disableActions &&
                            <>
                                <div className="action-buttons row justify-content-md-center">
                                    
                                    {!isRejected && item.state !== StoneState.PRIVATE &&
                                        <>
                                            <div className={"col action-buttons-item like" + (!!~likes.indexOf(currentUser.uid) || likes.find((like: any) => like.userId === currentUser.uid) ? ' liked' : '')} 
                                                onClick={() => handleIncreaseLikes(item)} >
                                                <AiOutlineLike /><span>{CS.components.stoneContent.like}</span>
                                            </div>
                                            <div className="col action-buttons-item comment" onClick={() => {
                                                item.showComments = !item.showComments
                                                setStones([...stones]);

                                            }}>
                                                <FaRegCommentAlt /><span>{CS.components.stoneContent.comments.title}</span>
                                            </div>

                                            {item.showComments && 
                                                <>
                                                    <hr />
                                                    <Comments 
                                                        stone={item} 
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                    {/* <div className="col action-buttons-item">
                                        <GoReport /><span>Nahlasit</span>
                                    </div> */}
                                    {item?.user?.userId === currentUser.uid && item.state === StoneState.REJECT &&
                                        <>
                                            <div className="col action-buttons-item delete" onClick={() => deleteStoneHandler(item) }>
                                                <FaTrash color="red" title="odstranit kaminek" />
                                                <span>Smazat</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }

                        {customActions && 
                            <div className="action-buttons row justify-content-md-center">
                                {customActions(rest)}
                            </div>
                        }


                        {/* <HandThumbsUpFill /> 27 */}
                    </div>
                </div>
            </div>
        </>
    );
};
